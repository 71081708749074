/**
 * UserView Actions Creator: create actions to store entities
 *
 */
import _,  { debounce }          from 'lodash';
import types                     from './types/userView';
import * as navigationsTypes     from './types/navigation';
import { get, patch, post, del } from 'utils/api';
import {
    curriedCompareProperty,
    deepMerge
}                                from 'utils/object';
import notification              from 'helpers/notification';
import { learn }                 from './knowledge';

import * as Sentry               from '@sentry/react';

export const chunkSize           = 25;

const fetchingModelsType         = {};
const fetchingOuterModels        = [];
const fetchUserViewModelsTimeOut = [];

let itemsToAddPool               = [],
    itemsToDeletePool            = [];

const WEAKMAP_USER_VIEW_ITEMS_BY_MODELS = new WeakMap();

/**
* Store a new model to the store
*
* @return {Promise}
*/
export const setUserView = (userView, span) => (dispatch, getState) => { // eslint-disable-line max-lines-per-function
    const {
            entities,
            entities_diff,
        } = userView,
        state  = getState(),
        userViewState = state.get('userView');

    // Manage modified entities
    if (entities_diff && entities_diff.length > 0) {
        const eventsWithModels = entities_diff.map(event => {
            const { item, action } = event,
                slice              = userViewState.get(item.entity_type),
                sliceMap           = slice?.get('map'),
                userViewItem       = sliceMap?.get(item.key),
                newItem            =  {
                    ...item,
                    model: userViewItem?.model,
                };

            return { item: newItem, action };
        });

        dispatch({
            type   : types.USER_VIEW_UPDATE_FROM_DIFF,
            payload: eventsWithModels,
        });

        const modelsTypeAdded = _.uniq(
            eventsWithModels.reduce(
                (types, event) => {
                    if (event.action === 'add') {
                        types.push(event.item.entity_type);
                    }
                    return types;
                }, []
            )
        );

        // Populate models
        modelsTypeAdded.forEach(entityType => fetchUserViewModels({modelType: entityType, span})(dispatch, getState));
        return;
    }

    // Manage full user view
    if (entities && _.keys(entities).length > 0) {
        dispatch({
            type   : types.USER_VIEW_SET,
            payload: { entities, span },
        });

        // Populate models
        _.keys(entities).forEach(entityType => fetchUserViewModels({modelType: entityType, span})(dispatch, getState));
        return;
    }

    return;
};


/**
 * Get all model ids fetched
 *
 * @param {*} userView
 * @returns
 */
const getFetchedModelsIds = (userView) => {
    const allModelsIds = [];

    _.forIn(
        userView.toJS(),
        (userViewSlice) => {
            const models = _.values(userViewSlice.map).reduce((accumulator, userviewItem) => {
                if (userviewItem.model?.type) {
                    accumulator.push(userviewItem.model);
                }
                return accumulator;
            }, []);

            // Clean the userViewState


            allModelsIds.push(models.map(model => model.id));
        }
    );

    return _.flatten(allModelsIds);
};

/**
* It fetches the models that are not yet fetched in the user view
*
* @param modelType         String The type of the model to fetch (e.g. 'users', 'groups', 'folders', etc.)
* @param userViewItemsKeys Array  Keys to specify userViewItems to fetch.
*/
export const fetchUserViewModels = (options) => (
    dispatch, getState
) => {
    const { modelType, userViewItemsKeys, span } = options;
    // Manage to relaunch fetchUserViewModels
    if (fetchingModelsType[modelType]) {
        clearTimeout(fetchUserViewModelsTimeOut[modelType]);
        fetchUserViewModelsTimeOut[modelType] = setTimeout(() => {
            fetchUserViewModels(options)(dispatch, getState);
        }, 1000);
        return;
    }

    fetchingModelsType[modelType] = true;

    const state          = getState(),
        userView         = state.get('userView'),
        fetchedModelsIds = getFetchedModelsIds(userView),
        slice            = userView.get(modelType),
        sliceList        = slice?.get('list'),
        ids              = sliceList
            ? sliceList.reduce((accumulator, userViewItem) => {
                if (
                    (!userViewItem.model || !userViewItem.model.type)
                    && userViewItem.entity_id
                    && (_.isUndefined(userViewItemsKeys) || userViewItemsKeys.includes(userViewItem.key))
                ) {
                    accumulator.push(userViewItem.entity_id);
                }
                return accumulator;
            }, [])
            : [],
        modelsIdsToFetch = _.difference(ids, fetchedModelsIds),
        noIdsToFetch      = modelsIdsToFetch.length === 0;

    // No model to fetch => slice is fully loaded
    if (noIdsToFetch) {
        fetchingModelsType[modelType] = false;
    }

    dispatch({
        type   : types.USER_VIEW_UPDATE_SLICE_STATS,
        payload: {
            modelType,
            values: { modelsAreLoaded: !fetchingModelsType[modelType] }
        },
    });

    !noIdsToFetch && fetchAndUpdateUserViewModels(modelType, modelsIdsToFetch, dispatch, span);
};


/**
 * Check if model of a type are loaded in user view
 *
 * @param {string} modelType
 * @returns boolean
 */
export const modelsAreLoaded = (modelType) => (dispatch, getState) => {
    const state = getState();

    return state.get('userView').get(modelType)?.get('stats').get('modelsAreLoaded');
};

/**
* Get Fetching model outer the user view scope
*
* @return {Promise}
*/
export const fetchOuterModels = (modelType, modelsIds) => (dispatch, getState) => {
    // Make sure pool is ready
    if (!fetchingOuterModels[modelType]) {
        fetchingOuterModels[modelType] = [];
    }

    const state           = getState(),
        outerModels       = state.get('userView').get(modelType)?.get('outerModels'),
        models            = outerModels?.filter(item => modelsIds.includes(item.id)),
        modelsIdsInOuter  = _.keys(models?.map(item => item.id).toJS()),
        modelsIdsToFetch  = _.difference(
            modelsIds,
            fetchingOuterModels[modelType].concat(modelsIdsInOuter)
        ),
        /**
         * Promise for the launchFetchPromises
         */
        promiseCb          = (resolve) => response => {
            const { body } = response;
            dispatch({
                type   : types.USER_VIEW_STORE_OUTER_MODELS,
                payload: body,
            });

            resolve();
        },
        fetchPromises      = launchFetchPromises(modelType, modelsIdsToFetch, promiseCb);

    // Slice is not ready or Ids are already fetched
    if (
        !modelsAreLoaded(modelType)(dispatch, getState)
        || modelsIdsToFetch.length === 0
    ) {
        return;
    }

    // Add fetching models to the pool
    fetchingOuterModels[modelType] = [
        ...fetchingOuterModels[modelType],
        ...modelsIdsToFetch
    ];

    Promise.all(fetchPromises).then(() => {
        // Clear fetching models pool
        fetchingOuterModels[modelType] = _.difference(fetchingOuterModels[modelType], modelsIdsToFetch);
    });

    return [];
};


/**
 * Make a promises for each chunk of ids to fetch.
 *
 * @param {string} modelType
 * @param {array} modelsIdsToFetch
 * @param {function} promiseCb
 * @returns
 */
const launchFetchPromises = (modelType, modelsIdsToFetch, promiseCb, span) => { // eslint-disable-line  max-params
    // Limit 20 because patent can be a slow factor
    const chunks = _.chunk(modelsIdsToFetch, 20);
    return chunks.map(
        chunk => new Promise((resolve) => {
            Sentry.withActiveSpan(span, () => {
                Sentry.startSpan(
                    {
                        op  : 'app.fetch-models-chunk',
                        name: 'Get models by chunk (' + modelType + ')',
                    }, async () => {
                        const result = await get(`/${modelType.replace('_', '-')}`, { data: { ids: chunk } });
                        promiseCb(resolve)(result);
                    }
                );
            });
        })
    );
};


/**
 * Fetching the user view models and updating them.
 *
*/
const fetchAndUpdateUserViewModels = (modelType, modelsIdsToFetch, dispatch, span) => { // eslint-disable-line  max-params
    // TODO: update outerModels slice
    Sentry.withActiveSpan(span, () => {
        Sentry.startSpan(
            {
                op              : 'app.userview.fetch.models',
                name            : 'Get models (' + modelType + ')',
                forceTransaction: true
            },
            async (childSpan) => {
                /**
                * Promise for the launchFetchPromises
                */
                const promiseCb = (resolve) => response => {
                    const { body } = response;
                    dispatch({
                        type   : types.USER_VIEW_UPDATE_MODELS,
                        payload: body,
                    });
                    resolve();
                };

                const fetchPromises  = launchFetchPromises(modelType, modelsIdsToFetch, promiseCb, childSpan);

                fetchingModelsType[modelType] = false;  // Wait for the slice is updated

                Promise.all(fetchPromises).then(() => {
                    dispatch({
                        type   : types.USER_VIEW_UPDATE_SLICE_STATS,
                        payload: {
                            modelType,
                            values: { modelsAreLoaded: true }
                        },
                    });
                });
            }
        );
    });
};


/**
* Store a new items to clipboard
*
* @return {Promise}
*/
export const addItems = (models, onAddItem) => async (dispatch, getState) => {
    // Ensure to know models definitions before add item (to know service of each models)
    const { models:modelsDefinitions } = await learn(['models'])(dispatch, getState),
        formattedModels = formatUserViewItems(models)(dispatch, getState),    // Format array of element or a single element
        cleanedModels   = formattedModels && formattedModels.map(item => cleanItemModel(item.model));

    // Create the temporal elements in userView
    dispatch({
        type   : types.USER_VIEW_ADD_NEW_ITEMS,
        payload: { items: formattedModels, userViewState: 'loading', modelsDefinitions }
    });

    // If onAddItem callback exists requests are sent immediately
    if(onAddItem) {
        await sendAddBulk(cleanedModels, onAddItem)(dispatch, getState);
        return;
    }
    // Otherwise, we use the pool and debounce
    itemsToAddPool = itemsToAddPool.concat(cleanedModels);
    addItemsDebounced(dispatch, getState);
};

/**
* Format items to be used in the userView store
* To merge the new item with the existing one from the store (useful for the bookmarks tags for example)
*
* @return {Promise}
*/
const formatUserViewItems = (data) => (dispatch, getState) => {
    const date              = new Date(),
        models              = !_.isArray(data) ? [data] : data;

    return models.map(item => {
        const { model, ...props } = item,
            existingUserViewItem  = model && getUserViewItemFromModel(model, model.type)(dispatch, getState) || {},
            { model:existingModel, entity_id, ...existingProps} =  existingUserViewItem || {};

        return  {
            id   : entity_id,
            model: {
                ...(deepMerge(existingModel, model || item)),
                id  : entity_id || crypto.randomUUID(),
                date: date.toUTCString(),

            },
            ...(deepMerge(existingProps, props))
        };
    });
};

/**
 * Remove unnecessary information from the model before sending it
 *
 * @param {*} model
 * @returns
 */
export const cleanItemModel = (item) => {
    const {type, entity, ...props} = item;

    if(type === 'bookmark') {
        return {
            type,
            entity: {
                entity: {
                    id  : entity?.entity?.id,
                    type: entity?.entity?.type
                }
            },
            ...props
        };
    }

    return item;
};

/**
* Send bulk post
*
* @return debounced function
*/
const addItemsDebounced = debounce((dispatch, getState) => {
    const splittedModels = _.chunk(itemsToAddPool, chunkSize);  // To avoid error Input variables exceeded 1000
    // Clean items pool
    itemsToAddPool = [];
    // Fire all promise in paralell
    splittedModels.map(chunk =>  sendAddBulk(chunk)(dispatch, getState));
}, 150);

/**
 * Send post request and replace remapped models if needed
 *
 * @param {*} models
 */
const sendAddBulk = (models, cb) => async (dispatch, getState) => {
    post('/bulk', { models }).then(value => {
        const { body }          = value,
            { remappedModels }  = body || {};
        replaceRemappedModels(remappedModels)(dispatch, getState);
        if (cb) {
            cb(remappedModels);
        }
    });
};

/**
 *  Replace all source models ids for target models id
 *
 * @param {*} remappedModels
 * @param {*} modelsDefinitions
 */
export const replaceRemappedModels = (remappedModels) => async (dispatch, getState) => {
    const state                      = getState(),
        userView                     = state.get('userView'),
        modelsToUpdate               = [],
        { models:modelsDefinitions } = await learn(['models'])(dispatch, getState);

    remappedModels && remappedModels.forEach(model => {
        const { source, target, type } = model,
            definition                 = modelsDefinitions.find(definition => definition.id === type),
            { service }                = definition || {},
            sourceModel                = userView.get(type).get('list')?.toJS()
                .find(se => se.entity_id === source),
            targetModel               = {
                ...sourceModel,
                ...{
                    entity_id: target,
                    key      : `${service}/${type}/${target}`,
                    model    : {...sourceModel?.model, id: target}
                }
            };

        definition && modelsToUpdate.push({source: sourceModel, target: targetModel});
    });

    if(modelsToUpdate.length > 0) {
        const payload = { items: modelsToUpdate, modelsDefinitions };
        dispatch({ type: types.USER_VIEW_REPLACE_ITEMS, payload });
    }
};



/**
* Remove items from clipboard
*
* @return {Promise}
*/
export const removeItems = (items) => (dispatch) => {
    dispatch({
        type   : types.USER_VIEW_UPDATE_ITEMS,
        payload: { items, userViewState: 'deleted' }
    });

    const itemsToDelete = items.map((item) => (
        { id: item.entity_id, type: item.model.type }
    ));
    itemsToDeletePool = [...itemsToDelete, ...itemsToDeletePool];

    removeItemsDebounced();
};


/**
* Send bulk delete
*
* @return debounced function
*/
const removeItemsDebounced = debounce(() => {
    const models = itemsToDeletePool;

    del('/bulk', { body: { models }});
    itemsToDeletePool = [];
}, 1000);


/**
* Save a newsletter
*
* @return {promise}
*/
export const saveNewsletter = (model) => () => {
    patch(`/newsletters/${model.id}`, _.pick(model, ['period', 'settings', 'label', 'key', 'entities']));
};



/**
 * Check if the entity is in a newsletter
 *
 * @param {string} entityId
 * @returns
 */
export const entityIsInNewsletter = (entity) => (dispatch, getState) => {
    const {
            type
        }                 = entity,
        isAQuery          = type === 'query',
        state             = getState(),
        userView          = state.get('userView'),
        newslettersSlice  = userView && userView.get('newsletter'),
        newslettersList   = newslettersSlice?.get('list'),
        activeNewsletters = _.map(newslettersList?.toJS(), 'model').filter(model => !!model),
        entitiesIds       = activeNewsletters?.reduce(
            (ids, newsletter) => ids.concat(newsletter.entities),
            []
        );

    if (entitiesIds && entitiesIds.includes(getDataEntity(entity).id)) {
        return true;
    }

    /* Checking if the newsletter is a topic newsletter.
       If it is, it will check the match of genericQueryId of queries
    */

    if (isAQuery) {
        const newsletterQueries       = getQueriesOfNewsletter(state),
            newsletterGenericQueryIds = newsletterQueries && newsletterQueries.map(query => query?.genericQueryId),
            dataEntity                = getDataEntity(entity),
            genericQueryId            = dataEntity?.genericQueryId;

        return newsletterGenericQueryIds?.includes(genericQueryId);
    }

    return false;
};

/**
 * Get a list of queries that are associated with the userView items of newsletters
 * @param state - The state of the store.
 * @returns A list of queries for the newsletter.
 */
const getQueriesOfNewsletter = (state) => {
    const userView        = state.get('userView'),
        entitiesSlice     = userView && userView.get('entity'),
        entitiesList      = entitiesSlice?.get('list'),
        newslettersSlice  = userView && userView.get('newsletter'),
        newslettersList   = newslettersSlice?.get('list'),
        topicNewsletter   = newslettersList?.find(userviewItem => userviewItem.model?.key === 'topic-insights'),
        entitiesIds       = topicNewsletter?.model.entities || [],
        queryEntities     = entitiesIds.map(
            id => entitiesList?.find(se => {
                const dataEntity = getDataEntity(se.model);
                return dataEntity && dataEntity.id === id;
            })
        ),
        newsletterQueries = queryEntities && queryEntities.map(se => se?.model && getDataEntity(se.model));

    return newsletterQueries;
};


/**
 * Get the data entity (the entity at the lowest level)
 *
 * @returns
 */
export const getDataEntity = (entity) => {
    if (!entity?.entity) {
        return entity;
    }

    return getDataEntity(entity.entity);
};


// TODO: Move newsletter functions to newsleter.js ...

/**
 * Add/Remove search to newsletter (topic-insight)
 *
 * @param {*} search
 */
export const toggleEntityInNewsletter = (entity, newsletterKey) => (dispatch, getState) => {
    const state                = getState(),
        { id, genericQueryId } = entity || {},
        newsletters            = getModelsOfType('newsletter')(dispatch, getState),
        newsletter             = newsletters?.find && _.cloneDeep(newsletters.find(
            newsletter => newsletter.key === newsletterKey)
        ),
        { entities, key }      = newsletter || {},
        isInNewsletter         = entities && entities.indexOf(id) !== -1,
        newsletterQueries      = getQueriesOfNewsletter(state),
        queriesIdsToRemove     = newsletterQueries
            ? newsletterQueries.filter(query => query?.genericQueryId === genericQueryId)
                .map(query => query.id)
            : [];

    if(!newsletter) {
        // Open Topic insight assitant
        return dispatch({
            type   : navigationsTypes.ACTIVATE_SHORTCUT,
            payload: {
                shortcutId: 'newsletters',
                options   : { key: newsletterKey, entityToAdd: id }
            }
        });
    }

    const newEntities = _.xor(
            entities,
            // Use query founded by genericQueryId
            queriesIdsToRemove.length > 0 ? queriesIdsToRemove : [id]
        ),
        isSearchNewLetter = key === 'topic-insights';

    // Keep at least one entity
    if (newEntities.length ===0) {
        return notification({
            type   : 'error',
            message: isSearchNewLetter
                ? 'Sorry, You must have at least one query in your Insight Feed.'
                : 'Sorry, you must have at least one company or organization in your Insight Feed.'
        });
    }

    // Toggle in entities
    newsletter.entities = newEntities;

    dispatch({
        type   : types.USER_VIEW_UPDATE_MODEL,
        payload: { ...newsletter, userViewState: 'saved' },
    });

    // Notify user
    notification(
        { message: isInNewsletter
            ? 'Successfully removed from your Insight Feed'
            : 'Successfully added to your Insight Feed'
        }
    );

    // Save model to api
    patch(`/newsletters/${newsletter.id}`, _.pick(newsletter, ['period', 'settings', 'label', 'key', 'entities']));

    // After the API finish the update of the userView,
    // The updated userView is sended by sockets
};

/**
 * Update a model from model.updated event
 */
export const updateUserViewModels = (models) => (dispatch) => {
    dispatch({
        type   : types.USER_VIEW_UPDATE_MODELS,
        payload: models,
    });
};


/**
 * Extract models of a specified type
 *
 * @param {string} type
 * @returns array
 */
export const getModelsOfType = (type) => (dispatch, getState) => {
    const state            = getState(),
        slice              = state?.get('userView'),
        sliceByType        = slice?.get(type),
        userViewItems      = sliceByType?.get('list').toJS(),
        models             = userViewItems && _.map(userViewItems, 'model').filter(se => !!se);

    return models || [];
};

/**
 *  Removing the new item from the model
 *
 * @param {object} model
 */
export const removeItemFromModel = (model) => (dispatch, getState) => {
    const userViewItem = getUserViewItemFromModel(model, model.type)(dispatch, getState);

    dispatch({
        type   : types.USER_VIEW_REMOVE_ITEMS,
        payload: [userViewItem],
    });
};

/**
 * Take items from the model and replace it from newItem
 *
 * @param model - The model that you want to replace.
 */
export const replaceItemFromModel = (model, newItem) => (dispatch, getState) => {
    const userViewItem = getUserViewItemFromModel(model, model.type)(dispatch, getState);

    learn(['models'])(dispatch, getState).then(({ models: modelsDefinitions }) => {
        const  payload = {
            items: [{
                source: userViewItem,
                target: newItem
            }],
            modelsDefinitions
        };
        dispatch({ type: types.USER_VIEW_REPLACE_ITEMS, payload });
    });
};



/**
 * It checks if the model is a query and if the query is bookmarked
 *
 * @param userViewItemModel Object The model of thus userView items
 * @param model             Object The model that is being compared to the userViewItemModel
 *
 * @returns A function to check if 2 models have the same genericQueryId.
 */
const isMatchingQuery = (userViewItemModel, model) => {
    const entityType = userViewItemModel?.entity?.entity.type || userViewItemModel?.type;
    const isQuery    = model.type !== 'bookmark'
        && entityType === 'entity'
        && userViewItemModel.entity.entity.entity.type === 'query';

    if (!isQuery) {
        return false;
    }

    const bookmarkedQuery              = userViewItemModel.entity.entity.entity;
    const genericQueryIdCompare        = curriedCompareProperty(bookmarkedQuery, 'genericQueryId');
    const bookmarkFolderForTagsCompare = curriedCompareProperty(bookmarkedQuery, 'settings.bookmarkFolderForTags');

    return genericQueryIdCompare(model, 'genericQueryId', 'entity')
        && bookmarkFolderForTagsCompare(model, 'settings.bookmarkFolderForTags', 'entity');
};


/**
 * Given a userViewItemModel and a model, return true if the userViewItemModel's entity id matches the
 * model's entity id.
 * @param userViewItemModel - The model from the userView item
 * @param model - The model that is being compared to the userViewItemModel.
 * @returns A function that takes two arguments, model and property, and returns a boolean.
 */
const isMatchingEntityId = (userViewItemModel, model) => {
    const entityIdCompare = curriedCompareProperty(userViewItemModel, 'id', 'entity');
    return entityIdCompare(model, 'id', 'entity');
};


/**
 * Get the userView item from the list
 *
 * @param {object} model
 * @param {ImmutableList} userViewItems
 *
 * @returns object The userView item
 */
const getUserViewItemFromList = (model, userViewItems) => {
    if (!userViewItems) {
        return null;
    }

    const userViewItem = userViewItems.find((userviewItem) => {
        const userViewItemModel = userviewItem.model;

        return userViewItemModel && (
            isMatchingEntityId(userViewItemModel, model) || (
                model.type === 'query' && isMatchingQuery(userViewItemModel, model)
            )
        );
    });

    return userViewItem || null;
};


/**
 * Return the bookmark from the entity
 *
 * @param {object} model
 * @param {string} type
 *
 * @return object The userView item
 */
export const getUserViewItemFromModel = (model, type, options = {}) => (dispatch, getState) => {
    const state               = getState(),
        userView              = state.get('userView'),
        slice                 = userView && userView.get(type),
        userViewItems         = slice?.get('list'),
        weakMap               = _.isObject(userViewItems)
            ? WEAKMAP_USER_VIEW_ITEMS_BY_MODELS.get(userViewItems) || {}
            : {},
        userViewItemByWeakMap = weakMap[model.id],
        { ignoreState }       = options,
        item                  = !_.isUndefined(userViewItemByWeakMap)
            ? userViewItemByWeakMap
            : getUserViewItemFromList(model, userViewItems);

    if(_.isUndefined(userViewItemByWeakMap) && _.isObject(userViewItems)) {
        weakMap[model.id] = item;
        WEAKMAP_USER_VIEW_ITEMS_BY_MODELS.set(userViewItems, weakMap);
    }

    return (ignoreState || item?.model?.userViewState !== 'deleted') ? item : null;
};



/**
 * Return the userview items from the models
 *
 * @param {object} model
 * @param {string} type
 *
 * @return object The userView item
 */
export const getUserViewItemsFromModels = (models, type, options = {}) => (dispatch, getState) => {
    const state         = getState(),
        userView        = state.get('userView'),
        slice           = userView && userView.get(type),
        userViewItems   = slice?.get('list')?.toJS(),
        { ignoreState } = options,
        items           = {},
        modelsToFind    = _.clone(models);

    if (userViewItems) {
        for (const key in userViewItems) {
            const userviewItem = userViewItems[key];
            const userViewItemModel = userviewItem?.model;

            if (!ignoreState && userViewItemModel?.userViewState === 'deleted') {
                continue;
            }

            for (const index in modelsToFind) {
                const model = modelsToFind[index];

                if (userViewItemModel && (
                    isMatchingEntityId(userViewItemModel, model) || (
                        model.type === 'query' && isMatchingQuery(userViewItemModel, model)
                    )
                )) {
                    items[model.id] = userviewItem;
                    modelsToFind.shift();
                    break;
                }
            }

            if (modelsToFind.length === 0) {
                break;
            }
        }
    }

    return items;
};

/**
 * Get the model identity from the key
 *
 * @param {string} key
 * @returns
 */
export const getModelIdentityFromKey = (key) => {
    const splittedKey = _.isString(key) && key.split('/');
    return splittedKey.length === 3 && {
        service: splittedKey[0],
        type   : splittedKey[1],
        id     : splittedKey[2],
        key
    };
};


/**
 * Check if a bookmark is a descendant of a folder
 *
 * @param {object} bookmark
 * @param {object} parent
 */
export const userViewItemIsDescendantOf = (userViewItem, folderId, parenType) => (dispatch, getState) => {
    const state         = getState(),
        itemsIdentities = userViewItem
            ? userViewItem.parent_attachments.map(
                item => {
                    const itemIdentity = getModelIdentityFromKey(item.key);
                    return itemIdentity;
                }
            ).filter(item => !parenType || item.type === parenType)
            : [],
        parentIds  = itemsIdentities.map(item => item.id);

    // Folder founded
    if (parentIds.includes(folderId)) {
        return true;
    }

    // Search in parents folders
    if (itemsIdentities.length > 0) {
        const parentItems = itemsIdentities.map(
            itemIdentity => state.get('userView').get(itemIdentity.type).get('map').get(itemIdentity.key)
        );

        for (const userViewItem of parentItems) {
            if (userViewItemIsDescendantOf(userViewItem, folderId, parenType)(dispatch, getState)) {
                return true;
            }
        }
    }

    return false;
};



// Export default
export default {
    setUserView,
    fetchUserViewModels,
    getUserViewItemFromModel,
    getUserViewItemsFromModels,
    updateUserViewModels,
    saveNewsletter,
    entityIsInNewsletter,
    toggleEntityInNewsletter,
    getModelsOfType,
    getDataEntity,
    removeItemFromModel,
    addItems,
    getModelIdentityFromKey,
    userViewItemIsDescendantOf,
    fetchOuterModels,
};
